import * as React from "react"
import { css } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import Link from "components/Link"
import { GATSBY_HOST_PATH } from "settings/env"
import styled from "@emotion/styled"
import { FooterVariant } from "components/Header/types"
import Container from "../container"
import FooterGroup, { FooterGroupLinksContainer } from "./FooterGroup"
import Logo from "../../images/latana-logo.svg"
import LogoWhite from "../../images/latana-logo-white.svg"
import CoverBackground from "../../images/Background-Dots.svg"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { P, SpanH3 } from "../headings"
import Button from "../button"
import NewsletterForm from "./NewsletterForm"
import { theme } from "../../common/colorScheme"

export const query = graphql`
	query FooterQuery {
		allContentfulSocialNetworks(filter: { active: { eq: true }, node_locale: { eq: "en-US" } }) {
			edges {
				node {
					order
					id
					title
					url
					icon {
						...MediaSocialNetworkIcon
					}
				}
			}
		}
		allContentfulFooterGroups(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					id
					order
					title
					bottom
					links {
						id
						text
						link {
							...FooterGroupLinkPage
							...FooterGroupLinkUrl
						}
					}
					additionalColumn
				}
			}
		}
	}
`

const footerStyles = css`
	content-visibility: initial;
`

const topStyles = css`
	padding: 80px 0;
`

const middleStyles = css`
	padding: 40px 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 30px 0;
	}
`

const bottomStyles = css`
	background: var(--main-color);
	padding: 15px 0;
	color: #fff;
`

const darkBottomStyles = css`
	background: #140230;
	padding: 15px 0;
	color: #fff;
`

const socialNetworksContainerStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-basis: 100%;
	width: 100%;

	${mediaBreakpoint(BreakPoints.LG)} {
		width: auto;
		flex-basis: auto;
		padding: 0;
	}
`

const socialNetworkStyles = css`
	margin: 0 6px;
	max-width: 28px;

	${mediaBreakpoint(BreakPoints.SM)} {
		width: auto;
		max-width: initial;
		max-height: initial;
	}
`

const middleLinksContainerStyles = css`
	${FooterGroupLinksContainer} {
		margin: 0 0 36px;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 0 -12px 0;
		${FooterGroupLinksContainer} {
			flex-direction: row;
			margin: 0;
			> a {
				margin: 0 12px;
			}
		}
	}
`

const groupsContainerStyles = css`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-wrap: initial;
	}
`

const logoStyles = css`
	height: 40px;
	margin: 0 0 30px;
	${mediaBreakpoint(BreakPoints.SM)} {
		height: 41px;
		margin: 0 0 34px;
	}
`

const descriptionContainerStyles = css`
	flex-basis: 100%;
	margin: 0 0 64px;
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-basis: 35%;
		margin: 0;
	}
`

const topGroupsContainerStyles = css`
	flex-basis: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.MD)} {
		flex-basis: 65%;
		flex-wrap: nowrap;
		margin: 10px 0 0 0;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		flex-direction: row;
	}
`

const descriptionStyles = css`
	max-width: 100%;
	font-size: 1.6rem;
	line-height: 2.4rem;
	margin: 0 0 24px;

	${mediaBreakpoint(BreakPoints.SM)} {
		font-size: 1.6rem;
		line-height: 2.4rem;
		margin: 0 0 32px;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		width: 282px;
	}

	span {
		margin: 0 5px;
	}

	:last-of-type {
		${mediaBreakpoint(BreakPoints.MD)} {
			margin: 0;
		}
	}
`

const middleContainerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
		align-items: center;
	}
`

const copyrightStyles = css`
	font-size: 1.2rem;
	line-height: 1.6rem;
	color: #000;
	margin: 0;
	a {
		text-decoration: none;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		font-size: 1.2rem;
		line-height: 1.6rem;
	}
`

const footerCoverStyles = css`
	display: flex;
	align-items: flex-start;
	min-height: 496px;
	background: url("${CoverBackground}") no-repeat center top var(--main-color);
	background-size: cover;
	padding: 75px 0 152px;
	min-height: 552px;
	${mediaBreakpoint(BreakPoints.SM)} {
		min-height: 496px;
		align-items: center;
		padding: 116px 0 80px;
	}
`

const coverHeadingStyles = css`
	color: #fff;
	text-align: center;
	margin: 0 0 24px;
`

const coverDescriptionStyles = css`
	width: 432px;
	max-width: 100%;
	text-align: center;
	color: #fff;
	margin: 0 0 42px;
`

const coverContainerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const newsletterCoverStyles = css`
	background: #fff;
	padding: 40px 0;
	${mediaBreakpoint(BreakPoints.MD)} {
		padding: 24px 0;
	}
`

const darknewsletterCoverStyles = css`
	background: #140230;
	padding: 40px 0;
	${mediaBreakpoint(BreakPoints.MD)} {
		padding: 24px 0;
	}
`

const newsletterContainerStyles = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	min-height: 100px;

	${mediaBreakpoint(BreakPoints.LG)} {
		flex-direction: row;
		align-items: flex-start;
	}
`

const Icon = styled.div<IconProps>`
	mask-image: ${({ url }) => `url(${url})`};
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	height: 28px;
	width: 28px;
	background-color: var(--main-color);
`

const IconBlack = styled.div<IconProps>`
	mask-image: ${({ url }) => `url(${url})`};
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;
	height: 28px;
	width: 28px;
	background-color: var(--white-color);
`

const footerGroupContainer = css`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex: 1;
`

const footerGroupWrapper = css`
	flex: 1;
	margin-bottom: ${theme.spacing(2)};
	${mediaBreakpoint(BreakPoints.LG)} {
		margin-bottom: initial;
	}
`

const footerGroupSplitWrapper = css`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	flex: 1;
`

const footerAdditionalGroupContainer = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: ${theme.spacing(1)};
`

const DarkStyles = css`
	background-color: var(--black-color);
	a {
		color: var(--white-color);
		&:hover {
			color: var(--white-color);
		}
	}
	span {
		color: var(--white-color);
	}
	p { 
		color: var(--white-color);
	}

`

const DefaultFooterConfig: FooterConfig = {
	hideDemo: false,
	hideNewsletter: false,
	variant: FooterVariant.DarkFooter ||  FooterVariant.Default
}

export interface FooterConfig {
	hideDemo: boolean
	hideNewsletter: boolean
	variant: string
}

interface IconProps {
	url: string
}

interface Props {
	siteTitle: string
	config?: FooterConfig
}

const Footer: React.FC<Props> = ({ siteTitle, config = DefaultFooterConfig }) => {
	const data = useStaticQuery(query)
	const { allContentfulSocialNetworks, allContentfulFooterGroups } = data

	const socialNetworks = React.useMemo(() => {
		return allContentfulSocialNetworks.edges.sort(({ node: nodeA }, { node: nodeB }) => nodeA.order - nodeB.order)
	}, [allContentfulSocialNetworks])

	const { top, topAdd, bottom } = React.useMemo(() => {
		const groups = allContentfulFooterGroups.edges.sort(({ node: nodeA }, { node: nodeB }) => nodeA.order - nodeB.order)
		const topGroups = groups.filter(({ node }) => !node.bottom && !node.additionalColumn)
		const topAdditional = groups.filter(({ node }) => node.additionalColumn)
		const bottomGroups = groups.filter(({ node }) => node.bottom)

		return {
			top: topGroups,
			topAdd: topAdditional,
			bottom: bottomGroups,
		}
	}, [allContentfulFooterGroups])

	const year = React.useMemo(() => {
		return new Date().getFullYear()
	}, [])

	const dark = React.useMemo(() => {
		return config && [FooterVariant.DarkFooter, FooterVariant.Default]
	}, [config])

	const themeIsBlack = config?.variant === FooterVariant.DarkFooter

	const getBlackStyles = React.useMemo(() => {
		if (dark)
			return [themeIsBlack  ? DarkStyles : footerStyles]
		return []
	}, [dark, config])

	const getMiddleStyles = React.useMemo(() => {
		if (dark)
			return [themeIsBlack  ? darknewsletterCoverStyles : newsletterCoverStyles]
		return []
	}, [dark, config])

	const getBottomStyles = React.useMemo(() => {
		if (dark)
			return [themeIsBlack  ? darkBottomStyles : bottomStyles]
		return []
	}, [dark, config])

	const getGroups = (groupData: any, additionalGroupData: any) => {
		if (!groupData) return null

		const groups = groupData.map(({ node }) => node)
		const lastTwoGroups = groups.splice(-2)
		const additionalGroups = additionalGroupData.map(({ node }) => node)

		return (
			<>
				<div css={footerGroupWrapper}>
					<div css={footerGroupContainer}>
						{groups.map(node => (
							<FooterGroup key={node.id} id={node.id} {...node} />
						))}
					</div>
				</div>
				<div css={footerGroupSplitWrapper}>
					<div css={footerGroupContainer}>
						{lastTwoGroups.map(node => (
							<FooterGroup key={node.id} id={node.id} {...node} />
						))}
					</div>
					<div css={footerGroupContainer}>
						<div css={footerAdditionalGroupContainer}>
							{additionalGroups.map(node => (
								<FooterGroup key={node.id} id={node.id} {...node} />
							))}
						</div>
					</div>
				</div>
			</>
		)
	}

	return (
		<footer css={[footerStyles, ...getBlackStyles]}>
			{!config.hideDemo ? (
				<section css={footerCoverStyles}>
					<Container css={coverContainerStyles}>
						<SpanH3 css={coverHeadingStyles}>
							Get in touch
						</SpanH3>
						<P css={coverDescriptionStyles}>
							Learn how you can use Latana to improve your brand marketing and grow faster.
						</P>
						<Button
							label="Book Demo"
							variant="black"
							link={
								{
									url: `${GATSBY_HOST_PATH}/book-demo/`,
								} as any
							}
							type="button"
						/>
					</Container>
				</section>
			) : null}
			<div css={topStyles}>
				<Container css={groupsContainerStyles}>
					<div css={descriptionContainerStyles}>
						<Link to="/">
							<img css={logoStyles } src={themeIsBlack ? LogoWhite : Logo} alt={siteTitle} title={siteTitle} height="41" width="135" />
						</Link>
						<P css={descriptionStyles}>
							We&apos;re here to help brands make better marketing decisions by delivering world-class, scalable
							insights.
						</P>
					</div>
					<div css={topGroupsContainerStyles}>{getGroups(top, topAdd)}</div>
				</Container>
			</div>
			<div css={[newsletterCoverStyles, ...getMiddleStyles]}>
				<Container css={newsletterContainerStyles}>
					<Container>
						<P css={copyrightStyles}>
						© {year}  Latana Brand Performance. All rights reserved. Various trademarks held by their owners.  · <a href="https://www.latana.com/legal-terms/impressum/" target="_blank" rel="noreferrer">Impressum</a> · <a href="https://www.latana.com/legal-terms/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> · <a href="www://wwww.latana.com/legal-terms/terms-and-conditions/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
						</P>
					</Container>
					<div css={socialNetworksContainerStyles}>
						{socialNetworks.map(({ node: { icon, url, id } }) => {
							const source = icon?.file.url

							return source ? (
								<div key={id} css={socialNetworkStyles}>
									<a href={url} target="_blank" rel="noopener noreferrer">
										{ themeIsBlack ? <IconBlack url={source} /> : <Icon url={source} /> }
									</a>
								</div>
							) : null
						})}
					</div>
				</Container>
			</div>
		</footer>
	)
}

export default React.memo(Footer)
